import React from "react";
import {useState} from "react";

import iconApple from '../images/icons/buttons/appStoreApple.svg';
import iconGoogle from '../images/icons/buttons/appStoreGoogle.svg';

const AppstoreButton = ({ link, type="google" }) => {

    let icon = '';
    let title = '';

    if (type === 'google') {
        icon = iconGoogle;
        title = 'Google Play';
    };
    if (type === 'apple') {
        icon = iconApple;
        title = 'App Store';
    }

    return (
        <a href={link} target="_blank" className="Appstore-Button">
            <img src={icon} className="AppStoreIcon" />
            {title}
        </a>
    )
};

export default AppstoreButton;
import iconApi from "../../images/icons/styled/second/CheckInStar.svg";
import iconStar from "../../images/icons/styled/first/CheckInStar.svg";

export default {
    "seo": {
        "title": "Online Banking for Business  | Bank 131",
        "description": "Send money to your partners and clients in rubles or another currency, enable recurring payments, monitor the account balance. You can browse the history of transactions, export payment drafts from the accounting systems and generate transaction statement."
    },
    "pageHeaderBlock": {
        "title": "Online Banking&nbsp;<br />for Business",
        "text": "<p>Send money to your partners and clients in rubles or another currency, enable recurring payments, monitor the account balance. </p><p>You can browse the history of transactions, export payment drafts from the accounting systems and generate transaction statement.</p>"
    },

    "submitApplicationBlock": {
        "cards": {
                "title": 'How to connect',
                "list": [
                    {
                        "title": 'Open an account in Bank 131',
                        "text": 'Become a client of the newest digital bank in Russia',
                        "circleWithImageSize": 'small',
                        "viewType": "ul"
                    },
                    {
                        "title": 'Access online banking app',
                        "text": 'Use all the banking functions online.',
                        "circleWithImageSize": 'small'
                    },
                ]
            },
        "textSubmit": "Start now",
        "textSubmitBottom": "Get in touch",
    },

    "gallery": {
        "showThumbnails": false,
        "showFullscreenButton": false,
        "showPlayButton": false,
        "showBullets": true,
        "showNav": true,
        "autoPlay": false,
        "items": [
            {
                "original": '/online-banking-slides/en/slide1.png',
            },
            {
                "original": '/online-banking-slides/en/slide2.png',
            },
            {
                "original": '/online-banking-slides/en/slide3.png',
            },
            {
                "original": '/online-banking-slides/en/slide4.png',
            }
        ]
    },

    "appstore": {
        "title": "Approve and decline transactions <span class=\"textDecorative_gradient_second\">in the online bank mobile app</span>",
        "infoCardsBlock": {
            "cards": [
                {
                    "title": 'Convenient',
                    "text": 'Have your digital signature at hand at all times, on your smartphone or tablet.',
                    "icon": iconStar,
                    "description": '',
                    "theme": 'withoutBackgroundSecondSmall',
                    "circleWithImageSize": "small"
                },
                {
                    "title": 'Quick',
                    "text": 'Sign documents with a single tap. Create and manage signature keys.',
                    "icon": iconStar,
                    "description": '',
                    "theme": 'withoutBackgroundSecondSmall',
                    "circleWithImageSize": "small"
                },
                {
                    "title": 'Secure',
                    "text": 'The digital signature of Bank 131 is a non-qualified electronic signature based on encryption algorithms. A signed document becomes an encrypted data package that cannot be forged.',
                    "icon": iconStar,
                    "description": '',
                    "theme": 'withoutBackgroundSecondSmall',
                    "circleWithImageSize": "small"
                }
            ]
        },
        "link_google": "https://play.google.com/store/apps/details?id=ru.bank131.mobilesign",
        "link_apple": "https://apps.apple.com/ru/app/%D0%B1%D0%B0%D0%BD%D0%BA-131-%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D1%8F-%D0%BF%D0%BE%D0%B4%D0%BF%D0%B8%D1%81%D1%8C/id1633410383"
    },

    "OnlinebankCardsBlock": {
        "cardsTitle": "Digital Payment Solutions",
        "cards": [
            {
                "title": "Make payments and receive transfers",
                "text": "You can transfer money directly via online banking to an individual or a company. Moreover, you can check who, when and how much money was transferred to your account.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Add templates",
                "text": "If the recurring payments don't suit you, create templates for often-repeated transactions. Next time, you will not need to fill in the details: just check the amount and confirm the payment.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Make transfers in foreign currency",
                "text": "You can open multiple bank accounts in rubles, dollars, and eurosand transfer money from them in the same currency. The service of currency buying/selling will be also available soon.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Set up recurring payments",
                "text": "Make a schedule for automatic sending of the money at the right time. Specify the number of payments and their frequency: once a year, once a month, or even every day.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Use outside of Russia",
                "text": "If you are abroad, you can also open an account and use all the possibilities of online banking with us. All documents and interfaces are in Russian and English.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
        ]
    }
}
import React from "react";
import * as PropTypes from "prop-types";

import ImageSlider from 'react-image-gallery';
import Block from "../blocks/Block";
import CircleWithImage from "../components/CircleWithImage";

import iconPrev from "../images/icons/styled/second/ImagePrev.svg";
import iconNext from "../images/icons/styled/second/ImageNext.svg";

import '../styles/components/imageGallery.scss';

const SubmitApplicationBlock = ({ gallery }) => {

    function renderLeftNav(onClick, disabled) {
        return (
          <button
            type="button"
            className="image-gallery-left-nav"
            aria-label="Prev Slide"
            disabled={disabled}
            onClick={onClick}
          >
            <CircleWithImage color="second" size="small">
                <img src={iconPrev} alt="prev slide" />
            </CircleWithImage>
          </button>
        );
    }

    function renderRightNav(onClick, disabled) {
            return (
              <button
                type="button"
                className="image-gallery-right-nav"
                aria-label="Next Slide"
                disabled={disabled}
                onClick={onClick}
              >
                <CircleWithImage color="second" size="small">
                    <img src={iconNext} alt="next slide" />
                </CircleWithImage>
              </button>
            );
        }


    return (
        <div className="Block-Inner">
            <div className="container">
                <ImageSlider
                    items={gallery.items}
                    showThumbnails={gallery.showThumbnails}
                    showFullscreenButton={gallery.showFullscreenButton}
                    showPlayButton={gallery.showPlayButton}
                    showBullets={gallery.showBullets}
                    showNav={gallery.showNav}
                    autoPlay={gallery.autoPlay}
                    renderLeftNav={renderLeftNav}
                    renderRightNav={renderRightNav}
                />
            </div>
        </div>

    );
};

SubmitApplicationBlock.propTypes = {
    title: PropTypes.string,
    textSubmit: PropTypes.string
};

export default SubmitApplicationBlock;

import iconApi from "../../images/icons/styled/second/CheckInStar.svg";
import iconStar from "../../images/icons/styled/first/CheckInStar.svg";

export default {
    "seo": {
        "title": "Интернет-банк для бизнеса  | Банк 131",
        "description": "Отправляйте деньги партнёрам и клиентам — в рублях или другой валюте, подключайте автоплатежи, следите за балансом. Можно посмотреть историю операций, загрузить платежи из системы учета и сформировать выписку для бухгалтера."
    },
    "pageHeaderBlock": {
        "title": "Интернет-банк&nbsp;<br />для бизнеса",
        "text": "<p>Отправляйте деньги партнёрам и клиентам — в рублях или другой валюте, подключайте автоплатежи, следите за балансом. </p><p>Можно посмотреть историю операций, загрузить платежи из системы учета и сформировать выписку для бухгалтера.</p>"
    },

    "submitApplicationBlock": {
        "cards": {
                "title": 'Как подключить',
                "list": [
                    {
                        "title": 'Открыть счёт в Банке 131',
                        "text": 'Станьте клиентом новейшего цифрового банка России',
                        "circleWithImageSize": 'small',
                        "viewType": "ul"
                    },
                    {
                        "title": 'Получить доступ к интернет-банку',
                        "text": 'Используйте все функции банка в режиме онлайн.',
                        "circleWithImageSize": 'small'
                    },
                ]
            },
        "textSubmit": "Подключиться"
    },

    "gallery": {
        "showThumbnails": false,
        "showFullscreenButton": false,
        "showPlayButton": false,
        "showBullets": true,
        "showNav": true,
        "autoPlay": false,
        "items": [
            {
                "original": '/online-banking-slides/ru/slide0.png',
            },
            {
                "original": '/online-banking-slides/ru/slide1.png',
            },
            {
                "original": '/online-banking-slides/ru/slide2.png',
            },
            {
                "original": '/online-banking-slides/ru/slide3.png',
            },
            {
                "original": '/online-banking-slides/ru/slide4.png',
            },
            {
                "original": '/online-banking-slides/ru/slide5.png',
            },
            {
                "original": '/online-banking-slides/ru/slide6.png',
            },
            {
                "original": '/online-banking-slides/ru/slide7.png',
            },
            {
                "original": '/online-banking-slides/ru/slide8.png',
            }
        ]
    },

    "appstore": {
        "title": "Создавайте операции в интернет банке – подписывайте или отклоняйте их <span class=\"textDecorative_gradient_second\">в мобильном приложении</span>",
        "infoCardsBlock": {
            "cards": [
                {
                    "title": 'Удобно',
                    "text": 'Цифровая подпись всегда под рукой — в вашем смартфоне или планшете. ',
                    "icon": iconStar,
                    "description": '',
                    "theme": 'withoutBackgroundSecondSmall',
                    "circleWithImageSize": "small"
                },
                {
                    "title": 'Быстро',
                    "text": 'Подписывайте документы в один клик. Создавайте новые ключи подписи и управляйте текущими. ',
                    "icon": iconStar,
                    "description": '',
                    "theme": 'withoutBackgroundSecondSmall',
                    "circleWithImageSize": "small"
                },
                {
                    "title": 'Безопасно',
                    "text": 'Цифровая подпись Банка 131 – это усиленная неквалифицированная электронная подпись (УНЭП),  в основе которой используются алгоритмы шифрования. Подписанный документ становится зашифрованным пакетом данных и не поддается подделке.',
                    "icon": iconStar,
                    "description": '',
                    "theme": 'withoutBackgroundSecondSmall',
                    "circleWithImageSize": "small"
                }
            ]
        },
        "link_google": "https://play.google.com/store/apps/details?id=ru.bank131.mobilesign",
        "link_apple": "https://apps.apple.com/ru/app/%D0%B1%D0%B0%D0%BD%D0%BA-131-%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D0%B0%D1%8F-%D0%BF%D0%BE%D0%B4%D0%BF%D0%B8%D1%81%D1%8C/id1633410383"
    },

    "OnlinebankCardsBlock": {
        "cardsTitle": "Цифровые платёжные решения",
        "cards": [
            {
                "title": "Платите и получайте деньги",
                "text": "Перевести деньги можно прямо из интернет-банка, кому угодно — физическому лицу или организации.И тут же проверить, кто, когда и сколько перевёл на ваш счёт.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Настройте автоплатежи",
                "text": "Составляйте расписание, чтобы деньги отправлялись сами, в нужный момент. Укажите количество платежей и когда их повторять: раз в год, раз в месяц или хоть каждый день.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Добавьте шаблоны",
                "text": "Если автоплатежи не подходят, создавайте шаблоны — для операций, которые часто повторяются.В следующий раз заполнять реквизиты не понадобится: только проверить сумму и подтвердить оплату.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Импортируйте данные",
                "text": "Добавляйте операции из другой системы, например, из 1С. Можно запланировать платежи в вашей онлайн-бухгалтерии, сохранить в файл и добавить в интернет-банк. Готово: деньги отправлены.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
            {
                "title": "Пользуйтесь за пределами России",
                "text": "Если вы за границей, можете открыть счёт и использовать все возможности интернет-банка. Все документы и интерфейсы есть на двух языках: русском и английском.",
                "icon": iconApi,
                "description": "",
                "theme": "cardFirst"
            },
        ]
    }
}
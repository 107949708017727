import React from "react";
import * as PropTypes from "prop-types";

import Block from "../blocks/Block";
import Title from "../components/typography/Title";
import InfoCardsBlock from "../blocks/InfoCardsBlock";

import AppstoreButton from "../components/AppstoreButton";

import '../styles/components/appStoreApplication.scss';
import SignAppImage from '../images/SignAppOnlineBanking/SignApp.png';

const AppStoreApplication = ({ data }) => {

    return (
        <div className="Block-Inner">
            <div className="container">
                <Title
                    level="h2"

                    className="Block-Title Title-Center AppStoreApplication-Title"
                    headerStyleNumb={'10'}
                    innerHTML={data.title}
                />

                <div className="AppStoreApplication-Features">
                    <div className="AppStoreApplication-Features-List">
                        <InfoCardsBlock
                            cardsTitleColor="second"
                            cardsLayout="row"
                            cards={data.infoCardsBlock.cards}
                            listPosition="afterCards"
                            listColor="second"
                            listAdditionalClass="baseList_theme_second_without_border AppStoreApplication-Features-List-Items"
                            haveQuestions={false}
                        />
                    </div>
                    <div className="AppStoreApplication-Features-Image">
                        <img src={SignAppImage} className="AppStoreApplication-Features-Image-Banner" />

                        <div className="AppStoreApplication-Features-Buttons">
                            {data.link_google &&
                                <div className="AppStoreApplication-Features-Buttons-Button">
                                    <AppstoreButton
                                        link={data.link_google}
                                        type="google"
                                    />
                                </div>
                            }
                            {data.link_google &&
                                <div className="AppStoreApplication-Features-Buttons-Button">
                                    <AppstoreButton
                                        link={data.link_apple}
                                        type="apple"
                                    />
                                </div>
                            }
                        </div>



                    </div>
                </div>
            </div>
        </div>

    );
};

export default AppStoreApplication;